/* VENDORS CSS */

/* Feather Icons - https://feathericons.com/ */

@import "partials/feather-icons";

/* Bootstrap 5 Grid - https://getbootstrap.com/docs/5.2/layout/grid/ */

@import "./src/assets/vendors/bootstrap/dist/css/bootstrap-grid.min";

/* Form Validation */

@import "./src/assets/vendors/formvalidation/css/formValidation.min";

/* Fancybox */

@import "./src/assets/vendors/fancybox/fancybox";

/* Swiper */

@import "./src/assets/vendors/swiper/swiper-bundle.min";
